<template>
  <div class="card">
    <SubTitle :title="'客户情况'">
      <template #right>
        <van-button v-if="!readonly" type="info" size="mini" @click="openPopup('客户情况登记', {})">登记</van-button>
      </template>
    </SubTitle>
    <van-row>
      <van-col span="6">是否见到本人</van-col>
      <van-col span="6">{{ familyData.seenInPerson | enumMessage(defaultEnum) | dash }}</van-col>
      <van-col span="6">是否长期居住</van-col>
      <van-col span="6">{{ familyData.longTermResident | enumMessage(defaultEnum) | dash }}</van-col>

      <van-col span="6">家庭人数</van-col>
      <van-col span="6">{{ familyData.familySize | dash }}</van-col>
      <van-col span="6">家庭结构</van-col>
      <van-col span="6">{{ familyData.familyStructure | dash }}</van-col>

      <van-col span="6">收入来源</van-col>
      <van-col span="6">{{ familyData.incomeSource | dash }}</van-col>
      <van-col span="6">家庭月收入</van-col>
      <van-col span="6">{{ familyData.monthlyExpenses | dash }}</van-col>

      <van-col span="6">车辆状态</van-col>
      <van-col span="6">{{ familyData.vehicleStatus | dash }}</van-col>
      <van-col span="6">停放位置</van-col>
      <van-col span="6">{{ familyData.parkingLocation | dash }}</van-col>
    </van-row>
    <div class="data-record" @click="goVisitRecords('family')">历史记录></div>

    <SubTitle title="上门记录"><template #right>
        <van-button v-if="!readonly" type="info" size="mini" @click="openPopup('上门情况登记', {})">新增</van-button>
      </template></SubTitle>
    <van-row>
      <van-col span="6">上门地址</van-col>
      <van-col span="6">{{ visitData.addressType | enumMessage(listAddr) | dash }}</van-col>
      <van-col span="12">{{ visitData.visitAddress | dash }}</van-col>

      <van-col span="6">家访对象</van-col>
      <van-col span="6">{{ visitData.visitObject | dash }}</van-col>
      <van-col span="6">外访日期</van-col>
      <van-col span="6">{{ visitData.visitDate | dash }}</van-col>

      <van-col span="6">是否有效</van-col>
      <van-col span="6">{{ visitData.addressVal | dash }}</van-col>
      <van-col span="6">外访情况</van-col>
      <van-col span="6">{{ visitData.visitCondition | dash }}</van-col>
    </van-row>
    <div class="data-record" @click="goVisitRecords('visit')">历史记录></div>

    <SubTitle title="回款结果"><template #right>
        <van-button v-if="!readonly" type="info" size="mini" @click="openPopup('回款记录登记', {})">填写信息</van-button>
      </template></SubTitle>
    <van-row>
      <van-col span="6">回款金额</van-col>
      <van-col span="6">{{ repaymentData.amount | dash }}</van-col>
      <van-col span="6">回款类型</van-col>
      <van-col span="6">{{ repaymentData.repaymentType | enumMessage(listType) | dash }}</van-col>

      <van-col span="6">回款人</van-col>
      <van-col span="6">{{ repaymentData.repaymentUserName | dash }}</van-col>
      <van-col span="6">回款时间</van-col>
      <van-col span="6">{{ repaymentData.repaymentDate | dash }}</van-col>
    </van-row>
    <div class="data-record" @click="goVisitRecords('repayment')">历史记录></div>

    <SubTitle title="反馈记录"><template #right>
        <van-button v-if="!readonly" type="info" size="mini" @click="openPopup('反馈登记', {})">新增</van-button>
      </template></SubTitle>
    <van-row>
      <van-col span="6">客户情况</van-col>
      <van-col span="6">{{ feedbackData.customCondition | enumMessage(getHomeCustomerConditionEnum) | dash }}</van-col>
      <van-col span="6">跟进结果</van-col>
      <van-col span="6">{{ feedbackData.followStatus | enumMessage(getHomeFollowStatusEnum) | dash }}</van-col>

      <van-col span="6">跟进时间</van-col>
      <van-col span="6">{{ feedbackData.followDate | dash }}</van-col>
      <van-col span="6">跟进详情</van-col>
      <van-col span="6">{{ feedbackData.followDetails | dash }}</van-col>
    </van-row>
    <div class="data-record" @click="goVisitRecords('feedback')">历史反馈></div>

    <PopupSlot v-model="showPopup" :actionType="actionType" :actionItem="actionItem" @closePopup="closePopup"
      @confirmPopup="confirmPopup">
      <van-form ref="refForm">
        <template v-if="['客户情况登记'].includes(
          actionType
        )
          ">
          <van-field required name="seenInPerson" label="是否见到本人：" class="form-no-boder"
            :rules="[{ required: true, message: '请选择' }]">
            <template #input>
              <van-radio-group v-model="form.seenInPerson" direction="horizontal">
                <van-radio v-for="item in defaultEnum" :name="item.value" :key="item.value">{{ item.displayName
                }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field required name="longTermResident" label="是否长期居住：" class="form-no-boder"
            :rules="[{ required: true, message: '请选择' }]">
            <template #input>
              <van-radio-group v-model="form.longTermResident" direction="horizontal">
                <van-radio v-for="item in defaultEnum" :name="item.value" :key="item.value">{{ item.displayName
                }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field required type="number" :label="`家庭人数：`" v-model="form.familySize" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入家庭人数' }]"></van-field>
          <van-field required :label="`家庭结构：`" v-model="form.familyStructure" :placeholder="`请输入示例(1老人1大人2小孩)`"
            :rules="[{ required: true, message: '请输入家庭结构' }]"></van-field>
          <van-field required :label="`收入来源：`" v-model="form.incomeSource" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入收入来源' }]"></van-field>
          <van-field required :label="`家庭月收入(元)：`" type="number" v-model="form.monthlyExpenses" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入家庭月收入' }]"></van-field>
          <van-field required :label="`车辆状态：`" v-model="form.vehicleStatus" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入车辆状态' }]"></van-field>
          <van-field :label="`停放位置：`" v-model="form.parkingLocation" :placeholder="`请输入`"></van-field>
        </template>

        <template v-if="['上门情况登记'].includes(
          actionType
        )
          ">
          <van-field readonly required clickable :label="`上门地址：`" :value="form.visitAddress" :placeholder="`请选择`"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowAddr(true)"></van-field>
          <van-field v-if="form.visitAddress" readonly :label="`${form.visitAddress}：`" class="form-no-boder"
            v-model="form.visitAddressText" :placeholder="`-`"></van-field>
          <van-field readonly required clickable :label="'外访日期：'" :value="form.valueDate" placeholder="请选择日期"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowDate(true)" />
          <van-field required :label="`家访对象：`" v-model="form.visitObject" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入' }]"></van-field>
          <van-field required :label="`外访情况：`" v-model="form.visitCondition" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入' }]"></van-field>
        </template>

        <template v-if="['回款记录登记'].includes(
          actionType
        )
          ">
          <van-field readonly required clickable label="回款类型：" :value="form.repaymentTypeText" placeholder="请选择"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowType(true)" />
          <van-field required :label="`回款金额：`" type="number" v-model="form.amount" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入' }]"></van-field>
          <van-field required :label="`回款人：`" v-model="form.repaymentUserName" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入' }]"></van-field>
          <van-field readonly required clickable :label="'回款时间：'" :value="form.valueDate" placeholder="请选择日期"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowDate(true)" />
          <van-field required name="attachList" label="回款凭证：" :rules="[{ required: true, message: '请选择' }]"
            class="form-no-boder">
            <template #input>
              <Uploader accept="image/*" v-model="form.attachList" />
            </template>
          </van-field>
        </template>

        <template v-if="['反馈登记'].includes(
          actionType
        )
          ">
          <van-field readonly required clickable label="客户情况：" :value="form.customConditionText" placeholder="请选择"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowInfo(true)" />
          <van-field readonly required clickable label="跟进结果：" :value="form.followStatusText" placeholder="请选择"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowResult(true)" />
          <van-field readonly required clickable :label="'跟进时间：'" :value="form.valueDate" placeholder="请选择日期"
            :rules="[{ required: true, message: '请选择' }]" @click="setShowDate(true)" />
          <van-field required :label="`跟进详情：`" v-model="form.followDetails" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入' }]"></van-field>
          <van-field name="feedBackAttachList" label="附件：" class="form-no-boder">
            <template #input>
              <Uploader accept="image/*" v-model="form.feedBackAttachList" />
            </template>
          </van-field>
        </template>

        <van-popup v-model="showPicker" round position="bottom" @close="closePicker">
          <van-picker v-if="showPickerAddr" show-toolbar :columns="listAddr" @confirm="onConfirmAddr" @cancel="closePicker" />
          <van-picker v-if="showPickerType" show-toolbar :columns="listType" @confirm="onConfirmType" @cancel="closePicker"/>
          <van-picker v-if="showPickerInfo" show-toolbar :columns="listInfo" @confirm="onConfirmInfo" @cancel="closePicker"/>
          <van-picker v-if="showPickerResult" show-toolbar :columns="listResult" @confirm="onConfirmResult" @cancel="closePicker"/>
          <van-datetime-picker v-if="showPickerDate" v-model="currentDate" type="date" title="选择年月日" :min-date="minDate"
            :max-date="maxDate" @confirm="onConfirmDate" @cancel="closePicker"/>
        </van-popup>
      </van-form>
    </PopupSlot>
  </div>
</template>

<script>
import dayjs from "dayjs";
import * as _ from "lodash";
import { Toast } from "vant";
import Api from "@/api/caseHandle";
import ApiDetail from "@/api/caseDetail";
const DEFAULT_ENUM = [{
  value: '1',
  displayName: '是'
}, {
  value: '0',
  displayName: '否'
}]
const DEFAULT_FORM = {
  valueDate: "", //日期

  seenInPerson: "", //是否见到本人
  longTermResident: "", //是否长期居住
  familySize: "", //家庭人数
  familyStructure: "", //家庭结构
  incomeSource: "", //收入来源
  monthlyExpenses: "", //家庭月收入
  vehicleStatus: "", //车辆状态
  parkingLocation: "", //停放位置

  addressType: "", //地址类型
  visitAddress: "", //上门地址
  visitAddressText: "",
  visitDate: "", //外访日期
  visitObject: "", //家访对象
  visitCondition: "", //外访情况

  repaymentType: "", //回款类型
  repaymentTypeText: "",
  amount: "", //回款金额
  repaymentUserName: "", //回款金额
  repaymentDate: "", //回款日期
  attachList: [], //附件-回款凭证

  customCondition: "", //客户情况
  customConditionText: "",
  followStatus: "", //跟进结果
  followStatusText: "",
  followDate: "", //跟进日期
  followDetails: "", //跟进详情
  feedBackAttachList: [], //附件-反馈
};
const MapAddr = {
  'HJ': 'permanentzAddress',
  'JZ': 'liveAddress',
  'FC': 'fcAddr',
  'DW': 'unitAddress',
}

export default {
  props: {
    detailInfo: {
      type: Object,
      required: true,
    },
    homeNo: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    addrData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultEnum: DEFAULT_ENUM,
      actionType: "",
      actionItem: {},
      showPopup: false,
      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },
      showPicker: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2100, 12, 30),
      currentDate: new Date(),

      showPickerDate: false,
      showPickerAddr: false,
      // listAddr: [],
      showPickerType: false,
      // listType: [],
      showPickerInfo: false,
      // listInfo: [],
      showPickerResult: false,
      // listResult: [],

      familyData: {},
      visitData: {},
      repaymentData: {},
      feedbackData: {},
    };
  },
  computed: {
    listAddr() {
      return (this.getAddrTypeEnum || []).map(e => ({ ...e, text: e.displayName }));
    },
    listType() {
      return (this.getRepaymentTypeEnum || []).map(e => ({ ...e, text: e.displayName }));
    },
    listInfo() {
      return (this.getHomeCustomerConditionEnum || []).map(e => ({ ...e, text: e.displayName }));
    },
    listResult() {
      return (this.getHomeFollowStatusEnum || []).map(e => ({ ...e, text: e.displayName }));
    },
  },
  mounted() {
    this.familyPage()
    this.visitPage()
    this.repaymentPage()
    this.feedbackPage()
    this.fetchEnums("getHomeCustomerConditionEnum", "getHomeFollowStatusEnum", "getAddrTypeEnum", "getRepaymentTypeEnum");
  },
  methods: {
    // 家访客户情况登记分页查询
    async familyPage() {
      try {
        const res = await ApiDetail.familyPage({
          pageNo: 1,
          pageSize: 1,
          homeNo: this.homeNo,
        });
        let { data } = res
        let { list } = data
        this.familyData = list[0] || {};
      } catch (error) {
        Toast.fail(error);
      }
    },
    // 家访上门记录分页查询
    async visitPage() {
      try {
        const res = await ApiDetail.visitPage({
          pageNo: 1,
          pageSize: 1,
          homeNo: this.homeNo,
        });
        let { data } = res
        let { list } = data
        this.visitData = list[0] || {};
      } catch (error) {
        Toast.fail(error);
      }
    },
    // 家访回款记录分页查询
    async repaymentPage() {
      try {
        const res = await ApiDetail.repaymentPage({
          pageNo: 1,
          pageSize: 1,
          homeNo: this.homeNo,
        });
        let { data } = res
        let { list } = data
        this.repaymentData = list[0] || {};
      } catch (error) {
        Toast.fail(error);
      }
    },
    // 家访回款记录分页查询
    async feedbackPage() {
      try {
        const res = await Api.feedbackPage({
          pageNo: 1,
          pageSize: 1,
          homeNo: this.homeNo,
          assignNo: this.detailInfo.assignNo,
        });
        let { data } = res
        let { list } = data
        this.feedbackData = list[0] || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    goVisitRecords(type) {
      this.$router.push({
        name: "VisitRecords",
        query: { homeNo: this.homeNo, assignNo: this.detailInfo.assignNo, type }
      });
    },

    // 弹出层打开
    async openPopup(action, item) {
      this.actionType = action;
      this.actionItem = { ...item };
      this.showPopup = true;
    },
    // 关闭弹出层
    closePopup() {
      this.$set(this, "form", { ...DEFAULT_FORM });
      this.$refs["refForm"].resetValidation();
      this.closePicker();
    },
    closePicker() {
      this.showPicker = false;
      this.showPickerType = false;
      this.showPickerInfo = false;
      this.showPickerResult = false;
      this.showPickerDate = false;
      this.showPickerAddr = false;
    },
    // 弹出层确认
    async confirmPopup() {
      try {
        await this.$refs["refForm"].validate();
        let { actionType } = this;
        // this.showPopup = false;
        switch (actionType) {
          case '客户情况登记':
            this.familyCreate()
            break;
          case '上门情况登记':
            this.visitCreate()
            break;
          case '回款记录登记':
            this.repaymentCreate()
            break;
          case '反馈登记':
            this.addFeedback()
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
    setShowDate(val) {
      this.showPickerAddr = false;
      this.showPicker = val;
      this.showPickerDate = val;
    },
    onConfirmDate(value) {
      this.$set(this.form, "valueDate", dayjs(value).format("YYYY-MM-DD"));
      this.setShowDate(false)
    },
    setShowAddr(val) {
      this.showPicker = val;
      this.showPickerAddr = val;
    },
    // 选择确认
    onConfirmAddr(value) {
      this.$set(this.form, "visitAddress", value.text);
      this.$set(this.form, "addressType", value.value);
      this.$set(this.form, "visitAddressText", this.addrData[MapAddr[value.value]]);

      this.setShowAddr(false)
    },
    setShowType(val) {
      this.showPicker = val;
      this.showPickerType = val;
    },
    // 选择确认
    onConfirmType(value) {
      this.$set(this.form, "repaymentType", value.value);
      this.$set(this.form, "repaymentTypeText", value.displayName);
      this.setShowType(false)
    },
    setShowInfo(val) {
      this.showPicker = val;
      this.showPickerInfo = val;
    },
    // 选择确认
    onConfirmInfo(value) {
      this.$set(this.form, "customCondition", value.value);
      this.$set(this.form, "customConditionText", value.displayName);
      this.setShowInfo(false)
    },
    setShowResult(val) {
      this.showPicker = val;
      this.showPickerResult = val;
    },
    // 选择确认
    onConfirmResult(value) {
      this.$set(this.form, "followStatus", value.value);
      this.$set(this.form, "followStatusText", value.displayName);
      this.setShowResult(false)
    },

    // 家访客户情况登记新增
    async familyCreate() {
      let { form } = this;
      Toast.loading();
      try {
        let params = {
          homeNo: this.homeNo,
          seenInPerson: form.seenInPerson, //是否见到本人
          longTermResident: form.longTermResident, //是否长期居住
          familySize: form.familySize, //家庭人数
          familyStructure: form.familyStructure, //家庭结构
          incomeSource: form.incomeSource, //收入来源
          monthlyExpenses: form.monthlyExpenses, //家庭月收入
          vehicleStatus: form.vehicleStatus, //车辆状态
          parkingLocation: form.parkingLocation, //停放位置
        }
        const res = await ApiDetail.familyCreate(params);

        Toast.success(res.message);
        this.showPopup = false;
        this.familyPage();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 家访上门记录新增
    async visitCreate() {
      let { form } = this;
      Toast.loading();
      try {
        let params = {
          homeNo: this.homeNo,
          addressType: form.addressType, //上门地址
          // visitAddress: form.visitAddress, //上门地址
          visitAddress: form.visitAddressText, //上门地址
          visitDate: form.valueDate, //外访日期
          visitObject: form.visitObject, //家访对象
          visitCondition: form.visitCondition, //外访情况
        }
        const res = await ApiDetail.visitCreate(params);

        Toast.success(res.message);
        this.showPopup = false;
        this.visitPage();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 家访回款记录新增
    async repaymentCreate() {
      let { form } = this;
      Toast.loading();
      try {
        let params = {
          homeNo: this.homeNo,
          repaymentType: form.repaymentType, //回款类型
          amount: form.amount, //回款金额
          repaymentUserName: form.repaymentUserName, //回款金额
          repaymentDate: form.valueDate, //回款日期
          attachList: form.attachList, //附件-回款凭证
        }
        const res = await ApiDetail.repaymentCreate(params);

        Toast.success(res.message);
        this.showPopup = false;
        this.repaymentPage();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 新增案件反馈记录
    async addFeedback() {
      let { form, detailInfo } = this;
      Toast.loading();
      try {
        let params = {
          homeNo: this.homeNo,
          customCondition: form.customCondition, //客户情况
          followStatus: form.followStatus, //跟进结果
          followDate: form.valueDate, //跟进日期
          followDetails: form.followDetails, //跟进详情
          attachList: form.feedBackAttachList, //附件-反馈
          assignNo: detailInfo.assignNo,
          orgName: detailInfo?.homeBasicDetailDTO?.orgName,
        }
        const res = await Api.addFeedback(params);

        Toast.success(res.message);
        this.showPopup = false;
        this.feedbackPage();
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  padding: 8px 8px 20px 8px;

  /deep/ .van-row {
    background: #fff;
    color: #333333;
    font-size: 14px;
    border-left: 1px solid #333333;
    border-top: 1px solid #333333;

    .van-col {
      padding: 4px;
      text-align: center;
      line-height: 24px;
      border-right: 1px solid #333333;
      border-bottom: 1px solid #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.flex-between>div {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .table {
    margin-top: 10px;
    box-sizing: border-box;
    border: 1px solid #eaecf2;
    border-radius: 2px;

    .row {
      position: relative;
      background: #fff;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 15px 8px;

      &.title {
        font-weight: 650;
      }

      span {
        flex: 1;
        text-align: center;
        // &:first-child {
        //   text-align: left;
        // }
        // &:last-child {
        //   text-align: right;
        // }

        a {
          font-size: 12px;
          color: #3c86ff;
          letter-spacing: 0;
          text-align: justify;
          text-decoration: underline;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: 0px;
        height: 1px;
        left: 0;
        right: 0;
        background: #eaecf2;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
